






import { Vue, Component } from "vue-property-decorator";
import CrewMemberExpiringCertificatesTable from "@/components/crew-member/CrewMemberExpiringCertificatesTable.vue";

@Component({ components: { CrewMemberExpiringCertificatesTable } })
export default class ExpiringCertificatesOverview extends Vue {}
